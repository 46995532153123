import { template as template_2b33206bcaef4c61a2d46202ea5b3d50 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2b33206bcaef4c61a2d46202ea5b3d50(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
