import { template as template_93daebf7ac4f44908e268c9dc3a267b9 } from "@ember/template-compiler";
const FKLabel = template_93daebf7ac4f44908e268c9dc3a267b9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
